// src/App.js
import React from 'react';
import HeroSection from './components/HeroSection';
import AboutUs from './components/AboutUs';
import Technology from './components/Technology';
import Team from './components/Team';
import Navbar from './components/Navbar';
import Contact from './components/Contact.js';
import Footer from './components/Footer.js';
import DemoSection from './components/DemoSections.js';
import Partners from './components/Partners.js';

const App = () => {
  return (
    <div>
      <Navbar />
      <HeroSection />
      <AboutUs />
      <Partners />
      <Technology />
      <DemoSection />
      <Team />
      <Contact />
      <Footer />
    </div>
  );
};

export default App;
