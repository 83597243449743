import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import eithealth from '../assets/EIT.png'; 
import eitfunded from '../assets/EITFUNDED.png';

const Partners = () => {
  const partners = [
    {
      name: "EIT Health",
      logo: eithealth, // Replace with actual EIT Health logo
      description: "Supporting healthcare innovation across Europe"
    },
    {
      name: "European Union",
      logo: eitfunded, // Replace with actual EU logo
      description: "EIT Health is supported by the EU, a body of the European Union"
    }
  ];
  
    return (
      <Section id="partners">
        <Content>
          <motion.h2
            initial={{ y: 30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8 }}
          >
            Our Partners
          </motion.h2>
          <Description>
            We collaborate with a leading European institution to drive innovation 
            in healthcare.
          </Description>
          
          <PartnersGrid>
            {partners.map((partner, index) => (
              <PartnerCard
                key={index}
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.8, delay: index * 0.2 }}
              >
                <LogoWrapper>
                  <LogoImage 
                    src={partner.logo} 
                    alt={`${partner.name} logo`}
                  />
                </LogoWrapper>
              </PartnerCard>
            ))}
          </PartnersGrid>
        </Content>
      </Section>
    );
  };
  
  const Section = styled.section`
    padding: 100px 0;
    background-color: #ffffff;
    text-align: center;
  `;
  
  const Content = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  
    h2 {
      font-size: 2.5rem;
      font-weight: 700;
      margin-bottom: 1.5rem;
      background: linear-gradient(135deg, #3b82f6 0%, #9333ea 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  `;
  
  const Description = styled.p`
    font-size: 1.2rem;
    max-width: 800px;
    margin: 0 auto 60px;
    color: #333;
  `;
  
  const PartnersGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    max-width: 1200px;
    margin: 0 auto;
  `;
  
  const PartnerCard = styled(motion.div)`
    background-color: #f8f9fa;
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  
    &:hover {
      transform: translateY(-10px);
      box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    }
  `;
  
  const LogoWrapper = styled.div`
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  `;
  
  const LogoImage = styled.img`
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  `;
  
  export default Partners;